var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (view) {pug_html = pug_html + "\u003Cdiv class=\"modal-dialog game\"\u003E\u003Cdiv class=\"background-wrapper\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E";
if (view.closeButton) {
pug_html = pug_html + "\u003Cdiv class=\"button close\" type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\"\u003E&times;\u003C\u002Fdiv\u003E";
}
if (view.options.headerContent) {
pug_html = pug_html + "\u003Ch3\u003E" + (null == (pug_interp = view.options.headerContent) ? "" : pug_interp) + "\u003C\u002Fh3\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E";
if (view.options.bodyContent) {
pug_html = pug_html + "\u003Cdiv\u003E" + (null == (pug_interp = view.options.bodyContent) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body wait secret\"\u003E\u003Ch3\u003EReticulating Splines...\u003C\u002Fh3\u003E\u003Cdiv class=\"progress progress-striped active\"\u003E\u003Cdiv class=\"progress-bar\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-primary\" type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\" data-i18n=\"modal.okay\"\u003EOkay\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;