var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (state, view) {pug_mixins["heroOption"] = pug_interp = function(hero){
var block = (this && this.block), attributes = (this && this.attributes) || {};
var heroOriginal = hero.original
var selectedState
if (state.get('selectedHeroOriginal') === heroOriginal) {
selectedState = 'selected'
}
else
if (view.options.showCurrentHero && state.get('currentHeroOriginal') === heroOriginal) {
selectedState = 'current'
}
else {
selectedState = ''
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["hero-option",selectedState], [false,true]), false, true)+pug.attr("data-hero-original", heroOriginal, true, true)) + "\u003E\u003Cdiv class=\"hero-avatar\"\u003E\u003Cimg" + (pug.attr("src", view.getPortraitURL(hero), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"text-h5 hero-name\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = view.getHeroShortName(hero)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"hero-list\"\u003E";
if (view.heroes) {
// iterate view.heroes
;(function(){
  var $$obj = view.heroes;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var hero = $$obj[pug_index0];
if (hero.heroClass === 'Warrior') {
pug_mixins["heroOption"](hero);
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var hero = $$obj[pug_index0];
if (hero.heroClass === 'Warrior') {
pug_mixins["heroOption"](hero);
}
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"state" in locals_for_with?locals_for_with.state:typeof state!=="undefined"?state:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;