var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (view) {pug_html = pug_html + "\u003Cform class=\"modal-body eu-confirmation\"\u003E\u003Cdiv class=\"modal-body-content\"\u003E\u003Cdiv class=\"checkbox\"\u003E\u003Clabel class=\"control-label\" for=\"eu-confirmation-checkbox\"\u003E\u003Cinput" + (" id=\"eu-confirmation-checkbox\" type=\"checkbox\" value=\"\""+pug.attr("checked", view.state.get('euConfirmationGranted'), true, true)) + "\u003E\u003Cspan data-i18n=\"signup.eu_confirmation\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cp\u003E\u003Cem\u003E\u003Ca href=\"\u002Fprivacy#place-of-processing\" target=\"_blank\" data-i18n=\"signup.eu_confirmation_place_of_processing\"\u003E\u003C\u002Fa\u003E\u003C\u002Fem\u003E\u003C\u002Fp\u003E\u003Cbr\u003E\u003Cbr\u003E";
switch (view.signupState.get('path')){
case 'student':
pug_html = pug_html + "\u003Cp class=\"small\" data-i18n=\"signup.eu_confirmation_student\"\u003E\u003C\u002Fp\u003E";
  break;
case 'individual':
pug_html = pug_html + "\u003Cp class=\"small\" data-i18n=\"signup.eu_confirmation_individual\"\u003E   \u003C\u002Fp\u003E";
  break;
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C!-- In reverse order for tabbing purposes--\u003E\u003Cdiv class=\"history-nav-buttons\"\u003E\u003Cbutton" + (" class=\"forward-button btn btn-lg btn-navy\""+" type=\"button\""+pug.attr("disabled", !view.state.get('euConfirmationGranted'), true, true)) + "\u003E\u003Cspan data-i18n=\"common.continue\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"back-button btn btn-lg btn-navy-alt\" type=\"button\"\u003E\u003Cspan data-i18n=\"common.back\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";}.call(this,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;