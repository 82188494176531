var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (state, translate, view) {pug_html = pug_html + "\u003Cform class=\"modal-body coppa-deny\"\u003E\u003Cdiv class=\"modal-body-content\"\u003E\u003Cdiv class=\"parent-email-input-group form-group\"\u003E";
if (!view.state.get('parentEmailSent')) {
pug_html = pug_html + "\u003Clabel class=\"control-label text-h4\" for=\"parent-email-input\"\u003E\u003Cspan data-i18n=\"signup.enter_parent_email\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Cinput" + (" id=\"parent-email-input\" type=\"email\" name=\"parentEmail\""+pug.attr("value", state.get('parentEmail'), true, true)) + "\u003E";
if (state.get('error')) {
pug_html = pug_html + "\u003Cp class=\"small error\"\u003E\u003Cspan data-i18n=\"signup.parent_email_error\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cp" + (pug.attr("class", pug.classes(["error",(state.get('dontUseOurEmailSilly') ?  '' : 'hidden')], [false,true]), false, true)) + "\u003E\u003Cspan data-i18n=\"signup.dont_use_our_email_silly\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003Cp class=\"small parent-email-blurb render\"\u003E\u003Cspan\u003E" + (null == (pug_interp = translate('signup.parent_email_blurb').replace('{{email_link}}', '<a href="mailto:team@codecombat.com">team@codecombat.com</a>')) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
if (view.state.get('parentEmailSent')) {
pug_html = pug_html + "\u003Cp class=\"small parent-email-blurb\"\u003E\u003Cspan data-i18n=\"signup.parent_email_sent\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003Ca class=\"btn btn-navy btn-lg\" href=\"\u002Fplay\" data-dismiss=\"modal\"\u003EPlay without saving\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C!-- In reverse order for tabbing purposes--\u003E\u003Cdiv class=\"history-nav-buttons\"\u003E\u003Cbutton" + (" class=\"send-parent-email-button btn btn-lg btn-navy\""+" type=\"submit\""+pug.attr("disabled", state.get('parentEmailSent') || state.get('parentEmailSending') ||  state.get('dontUseOurEmailSilly'), true, true)) + "\u003E";
if (state.get('parentEmailSent')) {
pug_html = pug_html + "\u003Cspan data-i18n=\"common.sent\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan data-i18n=\"common.send\"\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fbutton\u003E\u003Cbutton class=\"back-btn btn btn-lg btn-navy-alt\" type=\"button\"\u003E\u003Cspan data-i18n=\"common.back\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";}.call(this,"state" in locals_for_with?locals_for_with.state:typeof state!=="undefined"?state:undefined,"translate" in locals_for_with?locals_for_with.translate:typeof translate!=="undefined"?translate:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;