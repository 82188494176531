var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (view) {pug_mixins["ozaria-close-button"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cbutton class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\"\u003E\u003Cimg alt=\"close icon\" src=\"\u002Fimages\u002Fcommon\u002Fmodal\u002FExit_Dusk.svg\"\u003E\u003C\u002Fbutton\u003E";
};
pug_mixins["modal-header-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (view.utils.isCodeCombat) {
pug_html = pug_html + "\u003Ch3\u003E";
switch (view.signupState.get('path')){
case 'student':
if (view.signupState.get('screen')==='segment-check') {
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.have_a_classcode\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.create_student_header\"\u003E\u003C\u002Fspan\u003E";
}
  break;
case 'teacher':
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.create_teacher_header\"\u003E\u003C\u002Fspan\u003E";
  break;
case 'individual':
if (view.signupState.get('subModalContinue')) {
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.complete_subscription\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.create_individual_header\"\u003E\u003C\u002Fspan\u003E";
}
  break;
default:
pug_html = pug_html + "\u003Cspan data-i18n=\"login.sign_up\"\u003E\u003C\u002Fspan\u003E";
  break;
}
pug_html = pug_html + "\u003C\u002Fh3\u003E";
}
else {
pug_html = pug_html + "\u003Ch2\u003E";
switch (view.signupState.get('path')){
case 'student':
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.create_student_header\"\u003E\u003C\u002Fspan\u003E";
  break;
case 'teacher':
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.create_teacher_header\"\u003E\u003C\u002Fspan\u003E";
  break;
case 'individual':
if (view.signupState.get('subModalContinue')) {
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.complete_subscription\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan data-i18n=\"signup.create_individual_header\"\u003E\u003C\u002Fspan\u003E";
}
  break;
default:
pug_html = pug_html + "\u003Cspan data-i18n=\"login.sign_up\"\u003E\u003C\u002Fspan\u003E";
  break;
}
pug_html = pug_html + "\u003C\u002Fh2\u003E";
}
};
pug_mixins["modal-footer-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (view.signupState.get('screen') !== 'confirmation' && (view.utils.isCodeCombat || view.signupState.get('screen') !== 'teacher-signup-component')) {
pug_html = pug_html + "\u003Cdiv class=\"modal-footer-content\"\u003E\u003Cdiv class=\"small-details rtl-allowed\"\u003E";
if (view.utils.isCodeCombat) {
pug_html = pug_html + "\u003Cspan class=\"spr\" data-i18n=\"signup.login_switch\"\u003E\u003C\u002Fspan\u003E\u003Ca class=\"login-link\" href=\"#\"\u003E\u003Cspan data-i18n=\"signup.sign_in\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"spr\" data-i18n=\"login.already_have_account1\"\u003E\u003C\u002Fspan\u003E\u003Ca class=\"login-link\" data-i18n=\"signup.sign_in\" href=\"#\"\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
};
pug_html = pug_html + "\u003C!-- this allows the extended templates to add class to .modal-dialog--\u003E";
let modalDialogClass = '';
let isOzaria = (view.utils && view.utils.isOzaria)
const path = view.signupState.get('path');
const screen = view.signupState.get('screen');
const ozariaStyle = ['oz-vs-coco','choose-account-type'].includes(screen) || screen==='segment-check' && path==='student'
modalDialogClass = ozariaStyle ? 'ozaria-modal' : ''
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["modal-dialog",modalDialogClass], [false,true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["modal-content","style-flat",isOzaria ? 'style-ozaria' : ''], [false,false,true]), false, true)+" id=\"modal-base-flat\"") + "\u003E";
if (view.utils.isCodeCombat && ['oz-vs-coco','choose-account-type'].includes(view.signupState.get('screen'))) {
pug_mixins["ozaria-close-button"]();
}
else
if (view.utils.isCodeCombat && view.signupState.get('screen') === 'segment-check' && view.signupState.get('path')==='student') {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["modal-header","ozaria-modal-header",view.signupState.get('path')], [false,false,true]), false, true)) + "\u003E\u003Cdiv\u003E";
pug_mixins["modal-header-content"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
pug_mixins["ozaria-close-button"]();
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["modal-header",view.signupState.get('path')], [false,true]), false, true)) + "\u003E\u003Cspan class=\"glyphicon glyphicon-remove button close\" data-dismiss=\"modal\" aria-hidden=\"true\"\u003E\u003C\u002Fspan\u003E";
pug_mixins["modal-header-content"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
switch (view.signupState.get('screen')){
case 'choose-account-type':
pug_html = pug_html + "\u003Cdiv id=\"choose-account-type-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'segment-check':
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([view.signupState.get('path')], [true]), false, true)+" id=\"segment-check-view\"") + "\u003E\u003C\u002Fdiv\u003E";
  break;
case 'eu-confirmation':
pug_html = pug_html + "\u003Cdiv id=\"eu-confirmation-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'oz-vs-coco':
pug_html = pug_html + "\u003Cdiv id=\"oz-vs-coco-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'basic-info':
pug_html = pug_html + "\u003Cdiv id=\"basic-info-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'coppa-deny':
pug_html = pug_html + "\u003Cdiv id=\"coppa-deny-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'sso-already-exists':
pug_html = pug_html + "\u003Cdiv id=\"single-sign-on-already-exists-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'sso-confirm':
pug_html = pug_html + "\u003Cdiv id=\"single-sign-on-confirm-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'extras':
pug_html = pug_html + "\u003Cdiv id=\"extras-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'confirmation':
pug_html = pug_html + "\u003Cdiv id=\"confirmation-view\"\u003E\u003C\u002Fdiv\u003E";
  break;
case 'teacher-signup-component':
pug_html = pug_html + "\u003Cdiv id=\"teacher-signup-component\"\u003E\u003C\u002Fdiv\u003E";
  break;
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["modal-footer",view.utils.isCodeCombat ? `screen-${view.signupState.get('screen')}` : view.signupState.get('path')], [false,true]), false, true)) + "\u003E";
pug_mixins["modal-footer-content"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;