var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (view) {pug_html = pug_html + "\u003Cform class=\"modal-body\" id=\"basic-info-form\"\u003E\u003Cdiv class=\"modal-body-content\"\u003E\u003Ch4\u003E\u003Cspan data-i18n=\"signup.sso_connected\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fh4\u003E\u003Cdiv class=\"small m-y-1\"\u003E";
var ssoUsed = view.signupState.get('ssoUsed');
if (ssoUsed === 'facebook') {
pug_html = pug_html + "\u003Cimg src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Ffacebook_small.png\"\u003E";
}
if (ssoUsed === 'gplus') {
pug_html = pug_html + "\u003Cimg src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fgplus_small.png\"\u003E";
}
pug_html = pug_html + "\u003Cb class=\"m-x-1\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = view.signupState.get('email')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fb\u003E\u003Cspan class=\"glyphicon glyphicon-ok-circle class-code-valid-icon\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"hr-text m-y-3\"\u003E\u003Chr\u003E\u003Cspan data-i18n=\"common.next\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-container\"\u003E\u003Cinput" + (" class=\"hidden\""+" name=\"email\""+pug.attr("value", view.signupState.get('email'), true, true)) + "\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-7 col-xs-offset-3\"\u003E\u003Clabel class=\"control-label\" for=\"username-input\"\u003E\u003Cspan data-i18n=\"general.username\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-5 col-xs-offset-3\"\u003E\u003Cinput" + (" class=\"form-control input-lg\""+" id=\"username-input\" name=\"name\""+pug.attr("value", view.signupState.get('signupForm').name, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-4 name-check\"\u003E";
var checkNameState = view.state.get('checkNameState');
if (checkNameState === 'checking') {
pug_html = pug_html + "\u003Cspan class=\"small\" data-i18n=\"signup.checking\"\u003E\u003C\u002Fspan\u003E";
}
if (checkNameState === 'exists') {
pug_html = pug_html + "\u003Cspan class=\"small\"\u003E\u003Cspan class=\"text-burgundy glyphicon glyphicon-remove-circle\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = view.state.get('suggestedNameText')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
if (checkNameState === 'available') {
pug_html = pug_html + "\u003Cspan class=\"small\"\u003E\u003Cspan class=\"text-forest glyphicon glyphicon-ok-circle\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Cspan data-i18n=\"signup.name_available\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group subscribe\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-7 col-xs-offset-3\"\u003E\u003Cdiv class=\"checkbox\"\u003E\u003Clabel\u003E\u003Cinput" + (" id=\"subscribe-input\" type=\"checkbox\" name=\"subscribe\""+pug.attr("checked", view.signupState.get('signupForm').subscribe[0] === 'on', true, true)) + "\u003E\u003Cspan data-i18n=\"signup.email_announcements\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C!-- In reverse order for tabbing purposes--\u003E\u003Cdiv class=\"history-nav-buttons\"\u003E";
if (view.signupState.get('path') === 'teacher') {
pug_html = pug_html + "\u003Cbutton class=\"next-button btn btn-lg btn-navy\" type=\"submit\"\u003E\u003Cspan data-i18n=\"common.next\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
else
if (view.signupState.get('subModalContinue')) {
pug_html = pug_html + "\u003Cbutton class=\"next-button btn btn-lg btn-navy\" id=\"create-account-btn\" type=\"submit\"\u003E\u003Cspan data-i18n=\"common.continue\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
else {
pug_html = pug_html + "\u003Cbutton class=\"next-button btn btn-lg btn-navy\" id=\"create-account-btn\" type=\"submit\"\u003E\u003Cspan data-i18n=\"login.sign_up\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Cbutton class=\"back-button btn btn-lg btn-navy-alt\" type=\"button\"\u003E\u003Cspan data-i18n=\"common.back\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";}.call(this,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;