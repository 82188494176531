var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (me, view) {pug_html = pug_html + "\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"modal-body-content\"\u003E\u003Ch4 class=\"m-y-1\" data-i18n=\"signup.account_created\"\u003E\u003C\u002Fh4\u003E\u003Cdiv class=\"confirm_container text-center m-y-1\"\u003E";
if (view.signupState.get('path') === 'student') {
pug_html = pug_html + "\u003Cp data-i18n=\"signup.confirm_student_blurb\"\u003E\u003C\u002Fp\u003E";
}
else {
if (view.hideEmail) {
pug_html = pug_html + "\u003Cp data-i18n=\"signup.confirm_individual_blurb_without_email\"\u003E\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp data-i18n=\"signup.confirm_individual_blurb\"\u003E\u003C\u002Fp\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"signup-info-box-wrapper\"\u003E\u003Cdiv class=\"text-burgundy\" data-i18n=\"signup.write_this_down\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"signup-info-box text-center\"\u003E";
if (me.get('name')) {
pug_html = pug_html + "\u003Ch4\u003E\u003Cb\u003E\u003Cspan data-i18n=\"general.username\"\u003E\u003C\u002Fspan\u003E: " + (pug.escape(null == (pug_interp = me.get('name')) ? "" : pug_interp)) + "\u003C\u002Fb\u003E\u003C\u002Fh4\u003E";
}
if (me.get('email')) {
pug_html = pug_html + "\u003Ch5\u003E\u003Cb\u003E";
var ssoUsed = view.signupState.get('ssoUsed');
if (ssoUsed === 'facebook') {
pug_html = pug_html + ("\u003Cimg class=\"m-r-1\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Ffacebook_small.png\"\u003E" + (pug.escape(null == (pug_interp = me.get('email')) ? "" : pug_interp)));
}
else
if (ssoUsed === 'gplus') {
pug_html = pug_html + ("\u003Cimg class=\"m-r-1\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fgplus_small.png\"\u003E" + (pug.escape(null == (pug_interp = me.get('email')) ? "" : pug_interp)));
}
else {
pug_html = pug_html + ("\u003Cspan data-i18n=\"general.email\"\u003E\u003C\u002Fspan\u003E: " + (pug.escape(null == (pug_interp = me.get('email')) ? "" : pug_interp)));
}
pug_html = pug_html + "\u003C\u002Fb\u003E\u003C\u002Fh5\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (view.signupState.get('wantInSchool')) {
pug_html = pug_html + "\u003Cdiv id=\"nces-search-input\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cbutton class=\"btn btn-navy btn-lg m-t-3\" id=\"start-btn\" data-i18n=\"signup.start_playing\"\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"me" in locals_for_with?locals_for_with.me:typeof me!=="undefined"?me:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;