var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function ($, currentXP, description, imgURL, leftXP, leftXPWidth, level, locked, newXP, newXPWidth, oldXPWidth, popup, style, title) {var addedClass = style + (locked === true ? ' locked' : '')
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["clearfix","achievement-body",addedClass], [false,false,true]), false, true)) + "\u003E\u003Cdiv class=\"achievement-icon\"\u003E\u003Cdiv class=\"achievement-image\"\u003E\u003Cimg" + (pug.attr("src", imgURL, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"achievement-content\"\u003E\u003Cdiv class=\"achievement-title\"\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cp class=\"achievement-description\"\u003E" + (pug.escape(null == (pug_interp = description) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
if (popup) {
pug_html = pug_html + "\u003Cdiv class=\"progress-wrapper\"\u003E\u003Cspan class=\"user-level\"\u003E" + (pug.escape(null == (pug_interp = level) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cdiv class=\"progress-bar-wrapper\"\u003E\u003Cdiv class=\"progress\"\u003E";
var currentTitle = $.i18n.t('achievements.current_xp_prefix') + currentXP + ' XP' + $.i18n.t('achievements.current_xp_postfix');
var newTitle = $.i18n.t('achievements.new_xp_prefix') + newXP + ' XP' + $.i18n.t('achievements.new_xp_postfix');
var leftTitle = $.i18n.t('achievements.left_xp_prefix') + leftXP + ' XP' + $.i18n.t('achievements.left_xp_infix') + (level+1) + $.i18n.t('achievements.left_xp_postfix');
pug_html = pug_html + "\u003Cdiv" + (" class=\"progress-bar xp-bar-old\""+pug.attr("style", pug.style({width: oldXPWidth + '%'}), true, true)+" data-toggle=\"tooltip\" data-placement=\"top\""+pug.attr("title", currentTitle, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"progress-bar xp-bar-new\""+pug.attr("style", pug.style({width: newXPWidth + '%'}), true, true)+" data-toggle=\"tooltip\""+pug.attr("title", newTitle, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"progress-bar xp-bar-left\""+pug.attr("style", pug.style({width: leftXPWidth + '%'}), true, true)+" data-toggle=\"tooltip\""+pug.attr("title", leftTitle, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"progress-bar-border\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"$" in locals_for_with?locals_for_with.$:typeof $!=="undefined"?$:undefined,"currentXP" in locals_for_with?locals_for_with.currentXP:typeof currentXP!=="undefined"?currentXP:undefined,"description" in locals_for_with?locals_for_with.description:typeof description!=="undefined"?description:undefined,"imgURL" in locals_for_with?locals_for_with.imgURL:typeof imgURL!=="undefined"?imgURL:undefined,"leftXP" in locals_for_with?locals_for_with.leftXP:typeof leftXP!=="undefined"?leftXP:undefined,"leftXPWidth" in locals_for_with?locals_for_with.leftXPWidth:typeof leftXPWidth!=="undefined"?leftXPWidth:undefined,"level" in locals_for_with?locals_for_with.level:typeof level!=="undefined"?level:undefined,"locked" in locals_for_with?locals_for_with.locked:typeof locked!=="undefined"?locked:undefined,"newXP" in locals_for_with?locals_for_with.newXP:typeof newXP!=="undefined"?newXP:undefined,"newXPWidth" in locals_for_with?locals_for_with.newXPWidth:typeof newXPWidth!=="undefined"?newXPWidth:undefined,"oldXPWidth" in locals_for_with?locals_for_with.oldXPWidth:typeof oldXPWidth!=="undefined"?oldXPWidth:undefined,"popup" in locals_for_with?locals_for_with.popup:typeof popup!=="undefined"?popup:undefined,"style" in locals_for_with?locals_for_with.style:typeof style!=="undefined"?style:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;