var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (imgURL, me, view, window) {pug_mixins["error-area"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"error-area\"\u003E";
var error = view.state.get('error');
if (error) {
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-7 col-xs-offset-3\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"alert alert-danger\"\u003E" + (pug.escape(null == (pug_interp = error) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["use-social-signon"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (view.utils.isCodeCombat) {
if (!view.showLibraryIdInsteadOfUsername) {
pug_html = pug_html + "\u003Cdiv class=\"hr-text\"\u003E\u003Chr\u003E\u003Cspan data-i18n=\"general.or\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"auth-network-logins text-center\"\u003E\u003Cdiv class=\"gplus-login\" id=\"google-login-button\"\u003E\u003Ca" + (" class=\"network-login\""+pug.attr("disabled", !view.signupState.get('gplusEnabled'), true, true)+" data-sso-used=\"gplus\"") + "\u003E\u003Cimg class=\"gplus-logo\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fgplus_sso_button2.svg\" draggable=\"false\"\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
if (!/(teacher|student)/.test(view.signupState.get('path'))) {
pug_html = pug_html + "\u003Cdiv class=\"facebook-login\"\u003E\u003Ca" + (" class=\"network-login\""+" id=\"facebook-signup-btn\""+pug.attr("disabled", !view.signupState.get('facebookEnabled'), true, true)+" data-sso-used=\"facebook\"") + "\u003E\u003Cimg class=\"facebook-logo\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Ffacebook_sso_button2.png\" draggable=\"false\"\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
if (/(teacher|student)/.test(view.signupState.get('path'))) {
pug_html = pug_html + "\u003Cdiv class=\"clever-login\"\u003E\u003Ca class=\"network-login\" id=\"clever-signup-btn\" data-sso-used=\"clever\"\u003E\u003Cimg class=\"clever-logo\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fclever_sso_button@2x.png\" draggable=\"false\"\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C!-- TODO: turn on once we can ask about school inside ConfirmationView--\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"auth-network-logins\"\u003E\u003Cdiv class=\"gplus-login\"\u003E\u003Ca" + (" class=\"network-login\""+" id=\"google-login-button\""+pug.attr("disabled", !view.signupState.get('gplusEnabled'), true, true)+" data-sso-used=\"gplus\"") + "\u003E";
var buttonURL = "/images/ozaria/common/log-in-google-sso-generic.svg"
if(view.signupState.get('path') == 'teacher')
imgURL = "/images/ozaria/common/log-in-google-sso.svg"
pug_html = pug_html + "\u003Cimg" + (" class=\"gplus-logo\""+pug.attr("src", buttonURL, true, true)+" draggable=\"false\"") + "\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C!-- TODO: turn on once we can ask about school inside ConfirmationView--\u003E\u003C!--if view.signupState.get('path') === 'individual' && (!me.get('country') || me.get('country') == 'united-states').checkbox#want-in-school\n  label.control-label(for=\"want-in-school-checkbox\")\n    input#want-in-school-checkbox(type=\"checkbox\" value=\"\")\n    span(data-i18n=\"signup.want_codecombat_in_school\")--\u003E\u003Cdiv class=\"row or-row\"\u003E\u003Cdiv class=\"line\"\u003E\u003C\u002Fdiv\u003E\u003Cp class=\"or\" data-i18n=\"login.or\"\u003E\u003C\u002Fp\u003E\u003Cdiv class=\"line\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
};
pug_html = pug_html + "\u003Cform class=\"modal-body basic-info\" id=\"basic-info-form\"\u003E";
var path = view.signupState.get('path')
pug_html = pug_html + "\u003Cdiv class=\"modal-body-content\"\u003E";
if (view.utils.isCodeCombat) {
if (me.get("preferredLanguage") == "he" || window.location.host == "il.codecombat.com") {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-info text-center\" dir=\"rtl\"\u003E\u003Cspan\u003Eתלמיד מערכת החינוך?\u003C\u002Fspan\u003E\u003Ca href=\"https:\u002F\u002Fpub.skillz-edu.org\u002Fportal\u002Fplayground\"\u003E התחבר דרך פורטל משרד החינוך\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
else {
if (me.useSocialSignOn()) {
pug_mixins["use-social-signon"]();
}
}
pug_html = pug_html + "\u003Cdiv class=\"form-container\"\u003E";
if (['student', 'teacher'].indexOf(view.signupState.get('path')) !== -1) {
pug_html = pug_html + "\u003Cdiv class=\"row full-name\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["col-xs-offset-3",(path === 'teacher' && !me.showChinaRegistration() ? 'col-xs-3' : 'col-xs-5')], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"control-label\" for=\"first-name-input\"\u003E\u003Cspan data-i18n=\"general.first_name\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Cinput" + (" class=\"form-control input-lg\""+" id=\"first-name-input\" name=\"firstName\""+pug.attr("value", view.signupState.get('signupForm').firstName, true, true)+" dir=\"auto\" spellcheck=\"false\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (!me.showChinaRegistration()) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([(path === 'teacher' ? 'col-xs-3' : 'col-xs-4')], [true]), false, true)) + "\u003E";
if (view.signupState.get('path') === 'teacher') {
pug_html = pug_html + "\u003Cdiv class=\"last-name form-group\"\u003E\u003Clabel class=\"control-label\" for=\"last-name-input\"\u003E\u003Cspan data-i18n=\"general.last_name\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Cinput" + (" class=\"form-control input-lg\""+" id=\"last-name-input\" name=\"lastName\""+pug.attr("value", view.signupState.get('signupForm').lastName, true, true)+" dir=\"auto\" spellcheck=\"false\"") + "\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"last-initial form-group\"\u003E\u003Clabel class=\"control-label\" for=\"last-name-input\"\u003E\u003Cspan data-i18n=\"general.last_initial\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Cinput" + (" class=\"form-control input-lg\""+" id=\"last-name-input\" name=\"lastName\" maxlength=\"1\""+pug.attr("value", view.signupState.get('signupForm').lastName, true, true)+" dir=\"auto\" spellcheck=\"false\"") + "\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"row rtl-allowed\"\u003E\u003Cdiv class=\"col-xs-5 col-xs-offset-3 rtl-allowed\"\u003E";
if (view.utils.isOzaria || !view.hideEmail) {
pug_html = pug_html + "\u003Clabel class=\"control-label\" for=\"email-input\"\u003E\u003Cspan data-i18n=\"share_progress_modal.form_label\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
if (view.signupState.get('path') === 'student') {
pug_html = pug_html + "\u003Cdiv class=\"help-block optional-help-block pull-right\"\u003E\u003Cspan class=\"optional-text\" data-i18n=\"signup.optional\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control input-lg\""+" id=\"email-input\" name=\"email\" type=\"email\""+pug.attr("value", view.signupState.get('signupForm').email, true, true)+" dir=\"auto\"") + "\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-4 email-check fancy-error rtl-allowed\"\u003E";
var checkEmailState = view.state.get('checkEmailState');
if (checkEmailState === 'checking') {
pug_html = pug_html + "\u003Cspan class=\"small\" data-i18n=\"signup.checking\"\u003E\u003C\u002Fspan\u003E";
}
if (checkEmailState === 'exists') {
pug_html = pug_html + "\u003Cspan class=\"small\"\u003E\u003Cspan class=\"text-burgundy glyphicon glyphicon-remove-circle\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Cspan data-i18n=\"signup.account_exists\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Ca class=\"login-link\" data-i18n=\"signup.sign_in\"\u003E\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
if (checkEmailState === 'available') {
pug_html = pug_html + "\u003Cspan class=\"small\"\u003E\u003Cspan class=\"text-forest glyphicon glyphicon-ok-circle\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Cspan data-i18n=\"signup.email_good\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (view.signupState.get('path') !== 'teacher') {
if (view.showLibraryIdInsteadOfUsername) {
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"row library-signup-text\"\u003E\u003Cspan data-i18n=\"library.library_signup_promotion\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"row rtl-allowed\"\u003E\u003Cdiv class=\"col-xs-5 col-xs-offset-3 rtl-allowed\"\u003E";
if (view.showLibraryIdInsteadOfUsername) {
pug_html = pug_html + "\u003Clabel class=\"control-label\" for=\"username-input\"\u003E\u003Cspan data-i18n=\"library.library_card\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
else {
pug_html = pug_html + "\u003Clabel class=\"control-label\" for=\"username-input\"\u003E\u003Cspan data-i18n=\"general.username\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control input-lg\""+" id=\"username-input\" name=\"name\""+pug.attr("value", view.signupState.get('signupForm').name, true, true)+" dir=\"auto\" spellcheck=\"false\"") + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-4 name-check fancy-error rtl-allowed\"\u003E";
var checkNameState = view.state.get('checkNameState');
if (checkNameState === 'checking') {
pug_html = pug_html + "\u003Cspan class=\"small\" data-i18n=\"signup.checking\"\u003E\u003C\u002Fspan\u003E";
}
if (checkNameState === 'exists') {
pug_html = pug_html + "\u003Cspan class=\"small\"\u003E\u003Cspan class=\"text-burgundy glyphicon glyphicon-remove-circle\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = view.state.get('suggestedNameText')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
if (checkNameState === 'available') {
pug_html = pug_html + "\u003Cspan class=\"small\"\u003E\u003Cspan class=\"text-forest glyphicon glyphicon-ok-circle\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Cspan data-i18n=\"signup.name_available\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"row rtl-allowed\"\u003E\u003Cdiv class=\"col-xs-5 col-xs-offset-3 rtl-allowed\"\u003E\u003Clabel class=\"control-label\" for=\"password-input\"\u003E\u003Cspan data-i18n=\"general.password\"\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Cinput" + (" class=\"form-control input-lg\""+" id=\"password-input\" name=\"password\" type=\"password\""+pug.attr("value", view.signupState.get('signupForm').password, true, true)+" dir=\"auto\"") + "\u003E\u003Csmall class=\"form-text text-muted\" data-i18n=\"signup.password_requirements\"\u003E\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (view.utils.isCodeCombat) {
pug_mixins["error-area"]();
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (view.utils.isCodeCombat && me.useSocialSignOn()) {
pug_mixins["use-social-signon"]();
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (view.utils.isOzaria) {
pug_mixins["error-area"]();
}
pug_html = pug_html + "\u003C!-- In reverse order for tabbing purposes--\u003E\u003Cdiv class=\"history-nav-buttons\"\u003E";
if (view.signupState.get('path') === 'teacher') {
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes(["next-button","btn","btn-lg",view.utils.isCodeCombat ? "btn-navy" : "ozaria-primary-button"], [false,false,false,true]), false, true)+" type=\"submit\"") + "\u003E\u003Cspan data-i18n=\"common.next\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
else
if (view.signupState.get('subModalContinue')) {
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes(["next-button","btn","btn-lg",view.utils.isCodeCombat ? "btn-navy" : "ozaria-primary-button"], [false,false,false,true]), false, true)+" id=\"create-account-btn\" type=\"submit\"") + "\u003E\u003Cspan data-i18n=\"common.continue\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
else {
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes(["next-button","btn","btn-lg",view.utils.isCodeCombat ? "btn-navy" : "ozaria-primary-button"], [false,false,false,true]), false, true)+" id=\"create-account-btn\" type=\"submit\"") + "\u003E";
if (view.utils.isCodeCombat) {
pug_html = pug_html + "\u003Cspan data-i18n=\"login.sign_up\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan data-i18n=\"common.next\"\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Cbutton" + (pug.attr("class", pug.classes(["back-button","btn","btn-lg",view.utils.isCodeCombat ? "btn-navy-alt" : "ozaria-secondary-button"], [false,false,false,true]), false, true)+" type=\"button\"") + "\u003E\u003Cspan data-i18n=\"common.back\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";}.call(this,"imgURL" in locals_for_with?locals_for_with.imgURL:typeof imgURL!=="undefined"?imgURL:undefined,"me" in locals_for_with?locals_for_with.me:typeof me!=="undefined"?me:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined,"window" in locals_for_with?locals_for_with.window:typeof window!=="undefined"?window:undefined));;return pug_html;};
module.exports = template;