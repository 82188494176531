<template>
  <svg
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.68359 6.70801C6.51042 6.70801 6.35547 6.64193 6.21875 6.50977L0.927734 1.0957C0.86849 1.03646 0.822917 0.970378 0.791016 0.897461C0.759115 0.819987 0.743164 0.737956 0.743164 0.651367C0.743164 0.532878 0.770508 0.425781 0.825195 0.330078C0.879883 0.234375 0.952799 0.15918 1.04395 0.104492C1.13965 0.0498047 1.24674 0.0224609 1.36523 0.0224609C1.53841 0.0224609 1.68652 0.0817057 1.80957 0.200195L7.0459 5.55273H6.31445L11.5508 0.200195C11.6784 0.0817057 11.8265 0.0224609 11.9951 0.0224609C12.1136 0.0224609 12.2184 0.0498047 12.3096 0.104492C12.4053 0.15918 12.4805 0.234375 12.5352 0.330078C12.5898 0.425781 12.6172 0.532878 12.6172 0.651367C12.6172 0.819987 12.5557 0.96582 12.4326 1.08887L7.1416 6.50977C7.08236 6.57357 7.01172 6.6237 6.92969 6.66016C6.85221 6.69206 6.77018 6.70801 6.68359 6.70801Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'CaretDown',
  props: {
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style scoped>
 span {
  margin-left: 0.35em;
 }
</style>
