var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (closeOnConfirm, view) {pug_html = pug_html + "\u003C!-- this allows the extended templates to add class to .modal-dialog--\u003E";
let modalDialogClass = '';
let isOzaria = (view.utils && view.utils.isOzaria)
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["modal-dialog",modalDialogClass], [false,true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["modal-content","style-flat",isOzaria ? 'style-ozaria' : ''], [false,false,true]), false, true)+" id=\"modal-base-flat\"") + "\u003E\u003Cdiv class=\"modal-header\"\u003E";
if (view.closeButton) {
pug_html = pug_html + "\u003Cdiv class=\"button close\" type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\"\u003E&times;\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ch3\u003E" + (pug.escape(null == (pug_interp = view.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cp\u003E" + (null == (pug_interp = view.body) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body wait secret\"\u003E\u003Ch3\u003ELoading...\u003C\u002Fh3\u003E\u003Cdiv class=\"progress progress-striped active\"\u003E\u003Cdiv class=\"progress-bar\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-secondary\" id=\"decline-button\" type=\"button\" data-dismiss=\"modal\"\u003E" + (pug.escape(null == (pug_interp = view.decline) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary\""+" id=\"confirm-button\" type=\"button\""+pug.attr("data-dismiss", (closeOnConfirm === true ? "modal" : undefined), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = view.confirm) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"closeOnConfirm" in locals_for_with?locals_for_with.closeOnConfirm:typeof closeOnConfirm!=="undefined"?closeOnConfirm:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;